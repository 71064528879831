.navigation-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 100%;
  padding: 0 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: top 0.3s;
  z-index: 1000; /* Ensure it's on top of other content */
}

.navigation-header.hidden {
  top: -100px; /* Hide the navigation */
}

.navigation-header.visible {
  top: 0; /* Show the navigation */
}

.navigation-logo-fixed {
  position: fixed;
  top: 20px;
  left: 30px;
  width: 140px;
  transition: opacity 0.3s;
  z-index: 1001; /* Ensure it's above other content */
}

.navigation-logo-fixed.hidden {
  opacity: 0;
  visibility: hidden;
}

.navigation-logo-fixed.visible {
  opacity: 1;
  visibility: visible;
}

.navigation-icon-fixed {
  position: fixed;
  top: 15px;
  left: 30px;
  width: 40px;
  transition: opacity 0.3s;
  z-index: 1001; /* Ensure it's above other content */
}

.navigation-icon-fixed.hidden {
  opacity: 0;
  visibility: hidden;
}

.navigation-icon-fixed.visible {
  opacity: 1;
  visibility: visible;
}

.navigation-menu {
  position: absolute;  
  width: 50%;
}


.navigation-menu li {
  margin: 0 15px;
}

.navigation-menu > a {
  text-decoration: none;
  color: var(--black);
  margin: 0 20px;
  text-transform: capitalize;
  font-family: var(--font-secondary);
}

.fixed-button-container {
  position: fixed;
  top: 10px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001; /* Ensure the container is above the navigation */
}

.get-started-button {
  background-color: white;
  border: 1px solid lightgray;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-radius: 100px;
  margin-bottom: 10px; /* Space between button and link */
}

.get-started-button p {
  margin: 0;
}

.learn-more-link {
  text-decoration: none;
  color: var(--blue);
  font-family: var(--font-secondary);
  font-size: 14px;
}




@media (max-width: 800px) {
  .navigation-header {
    padding: 0 10px;

  }

.navigation-logo-fixed {
  left: 15px;
}

.navigation-icon-fixed {
  left: 15px;
}
.fixed-button-container {

  right: 15px;
  display: flex;
}


}