/* src/components/PriceList.css */
.price-list-container {
    background: linear-gradient(to bottom, var(--skyblue), var(--background));
    padding: 80px;
    display: none;
    border-radius: 45px;
    margin-top: 150px;
    margin-bottom: 40px;
    width: 90%;
    max-width: 800px;
    transition: 200ms ease-in-out;
  }

  .price-list-container:hover {
      cursor: pointer;
      transform: scale(1.005);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  

  .price-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .price-header-left h1 {
    font-family: var(--font-primary);
    font-size: 42px;
    font-weight: 500;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .price-header-left p {
    font-family: var(--font-secondary);
    font-size: 18px;
    margin-top: 0px;
    text-align: left;
    line-height: 150%;
    max-width: 600px;
    color: rgb(73, 73, 73);
    width: 90%;
  }
  
  .price-header-right h5 {
    font-size: 16px;
    color: white;
    padding: 15px;
    font-weight: 400;
    font-family: var(--font-secondary);
    background-color: black;
    border-radius: 100px;
  }
  
  .price-list-content {
    background-color: white;
    border-radius: 35px;
    padding: 20px;
  }
  
  .price-list-item {
    border: 1px solid var(--skyblue);
    border-radius: 25px;
    padding: 20px;
  }
  
  .item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .item-header-left,
  .item-header-right {
    flex: 1;
    padding: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .item-header-left {
      border-right: 1px solid rgb(230, 230, 230);
  }
  
  .item-header-left h4,
  .item-header-right h4 {
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 10px;
    color: var(--blue);
    margin-bottom: 10px;
  }

    
  .item-header-left h3,
  .item-header-right h3 {
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  
  .item-header-left p,
  .item-header-right p {
    font-family: var(--font-secondary);
    font-size: 15px;
    color: rgb(156, 156, 156);
    max-width: 80%;
  }
  
  .item-features {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
  }
  
  .features-left,
  .features-right {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .features-left {
      margin-right: 30px;
      margin-left: 45px;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
  }
  
  .feature-item img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
  
  .start-trial-button {
    background-color: var(--black);
    color: white;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-family: var(--font-secondary);
    font-size: 18px;
    border: none;
    border-radius: 100px;
    margin-top: 30px;
    width: 100%;
    height: 70px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    cursor: pointer;
    transition: 140ms ease-in-out;
  }
  

  .start-trial-button:hover {
    transform: scale(1.02);
  }


  @media (max-width: 800px) {
    .price-list-container {
      padding:0px;
      width: 100%;
      border-radius: 0;
    }

    .price-list-header {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .item-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-direction: column;
    }
    .item-features {
      flex-direction: column;
      align-items: flex-start;
    }

    .features-left {
      margin-left: 0px;
      margin-right: 10px;
      text-align: left;
    }

    .price-header-left p {
      margin-left: 20px;
    }

    .price-header-left h1 {
      margin-left: 20px;
    }
    .price-header-right {
      margin-left: 20px;
      margin-bottom: 0px;
      height: 90px;
    }

    .item-header-left {
      border-right: none;
    }
  }