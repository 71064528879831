/* src/components/ExitPopup1.css */
.exit-popup-container {
    max-width: 500px;
    width: calc(95% - 40px);
    background: rgb(240, 240, 240);
    padding: 20px;
    border-radius: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    animation: popup-appear 0.3s ease-in-out;
    font-family: var(--font-secondary);
  }
  
  .exit-popup-close-btn {
    position: absolute;
    top: -35px;
    right: -35px;
    background: transparent;
    border: none;
    z-index: 100;
    font-size: 16px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }

  .popup-heading {
      font-size: 38px !important;
      font-family: var(--font-primary);
      font-weight: 400;
  }
  
  .popup-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 35px;
  }
  
  .popup-heading {
    margin: 20px 0;
    font-size: 24px;
  }
  
  .popup-benefits {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0px;
  }
  
  .benefit-item {
    display: flex;
    font-weight: 500;
    color: rgb(51, 51, 51);
    align-items: center;
    width: 46%;
    font-size: 15px;
    margin: 5px 0;
    background-color: rgb(255, 255, 255);
    padding: 2px 0 2px 15px;
    border-radius: 15px;
    font-family: var(--font-secondary);
  }
  
  .benefit-image-popup {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 50%;
  }
  
  .popup-button {
    width: 100%;
    margin: 20px 0 20px 0;
    background: var(--blue);
    color: white;
    height: 75px;
    font-weight: 600;
    border: none;
    border-radius: 100px;
    font-family: var(--font-secondary);
    font-size: 20px;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}
  
  .popup-circle-image {
    position: absolute;
    right: 30px;
    bottom: 80px;
    padding: 15px;
    display: none;
    rotate: 270deg;
    filter: invert(1);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.24);
    box-shadow: rgba(0rgb(255, 255, 255)0, 0.24) 0px 3px 8px;
  }
  
  .popup-small-text {
    font-size: 12px;
    color: #777;
  }

  @media(max-width: 500px) {
    .benefit-item {
      width: 100%;
    }
    .popup-image {
      height: 200px;
    }
    .exit-popup-close-btn {
      right: 5px;
      top: -5px;
    }
  }
  