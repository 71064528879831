/* src/components/UrgencyCountdown.css */
.urgency-countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    opacity: 0;
    position: absolute;
    transform: translateY(-20px);
    animation: fadeInSlideDown 1s forwards;
  }
  
  @keyframes fadeInSlideDown {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .urgency-countdown {
    display: flex;
    gap: 10px;
    color: var(--blue);
    padding: 7px 15px;
    border: 1px solid white;
    border-radius: 100px;
    font-size: 14px;
    font-weight: bold;
    margin-top: -85px;
    background: linear-gradient(90deg, white 25%, rgb(223, 217, 217) 50%, white 75%);
    background-size: 300% 100%;
    animation: shimmer 15s linear infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 300% 0;
    }
    100% {
      background-position: -300% 0;
    }
  }
  
  .urgency-time-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px -0px;
    border-radius: 100px;
  }
  
  .urgency-label {
    font-size: 10px;
    font-weight: 300;
    color: var(--blue);
  }
  

  .urgency-limited-offer > img {
    width: 25px;
    margin-right: 10px;
    animation: swing 1s infinite alternate;
  }
  
  @keyframes swing {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  @media (max-width: 800px) {
    .urgency-countdown-container {
      display: none;
    }
  }
  