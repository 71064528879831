/* src/components/OfferList.css */
.offer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 90%;
    max-width: 1000px;
  }
  
  .offer-list > h2 {
      text-align: left;
      width: 100%;
      font-family: var(--font-secondary);
      font-weight: 400;
  }