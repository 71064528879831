/* src/components/Offer.css */
.offer-wrapper {
    background: linear-gradient(342deg, var(--blue), white, var(--blue));
    background-size: 200% 200%;
    border-radius: 30px; /* Slightly larger than the offer's border-radius */
    display: inline-block;
    height: 305px;
    padding: 0 8px;
    margin: 50px 0;
    display: flex;
    box-shadow: rgba(50, 50, 93, 0.01) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px;
    width: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: gradient-animation 3s ease infinite;
    transform: scale(0.95);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .offer-wrapper.offer-visible {
    opacity: 1;
    transform: scale(1);
  }
  
  /* Animation Keyframes */
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .offer {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    width: 100%;
    max-width: 1000px;
    min-height: 250px;
    border: 1px solid rgb(214, 214, 214);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;
  }
  
  .offer-left {
    flex-grow: 1;
    max-width: 65%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
  }
  
  .offer-header {
    display: flex;
    align-items: center;
    text-align: left;
    align-items: flex-start;
    justify-content: left;
  }
  
  .offer-icon {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-top: -5px;
  }
  
  .offer-title {
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
    font-family: var(--font-secondary);
  }
  
  .offer-description {
    font-size: 16px;
    font-family: var(--font-secondary);
    text-align: left;
    max-width: 800px;
    font-weight: 400;
    color: rgb(75, 75, 75);
    line-height: 200%;
  }
  
  .offer-image {
    width: 350px;
    position: absolute;
    right: -80px;
    height: auto;
    margin-left: 20px;
  }
  
  .offer-left > h5 {
    text-align: left;
    color: var(--blue);
  }

  @media (max-width: 900px) {
    .offer {
      flex-direction: column-reverse;
      height: 460px;
      width: 95%;
    }

    .offer-left {
      width: 100%;
      max-width: unset;
    }

    .offer-image {
      position: relative;
      right: 0;
      top: -10px;
      width: 110%;
      object-fit: contain;
      max-height: 250px;

    }


    .offer-wrapper {
      height: 520px;
    }
  }


  @media (max-width: 600px) {
    .offer {
      flex-direction: column-reverse;
      height: 460px;
      width: 90%;
    }
  }
