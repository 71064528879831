body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --skyblue: #D9E4F8;
  --black: rgb(20, 20, 20); 
  --blue: #6897B7; 
  --backgroundOld: #F6F6F7;
  --background: #FCF9F1;
  --font-primary: 'Cursive', sans-serif;
  --font-secondary: 'Neue', sans-serif;
}



@font-face {
  font-family: 'Cursive';
  src: url('/public/fonts/Cursive-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Cursive';
  src: url('/public/fonts/Cursive-Ultra.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Cursive';
  src: url('/public/fonts/Cursive-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cursive';
  src: url('/public/fonts/Cursive-Light.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cursive';
  src: url('/public/fonts/Cursive-Ultralight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue';
  src: url('/public/fonts/Neue-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Neue';
  src: url('/public/fonts/Neue-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue';
  src: url('/public/fonts/Neue-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue';
  src: url('/public/fonts/Neue-Book.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue';
  src: url('/public/fonts/Neue-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gaia';
  src: url('/public/fonts/Gaia-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gaia';
  src: url('/public/fonts/Gaia-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}