/* src/components/ReviewGallery.css */
.review-gallery {
    position: relative;
    text-align: center;
    padding: 20px;
    margin-bottom: 200px;
    max-width: 1000px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .gallery-title {
    margin-bottom: 60px;
    font-family: var(--font-primary);
    font-size: 36px;
    max-width: 600px;
    font-weight: 400;
    width: 100%;
  }


  
  .gallery-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gallery-image {
    width: 1000px;
    height: 600px;
    object-fit: cover;
    border-radius: 25px;
  }
  
  .nav-button {
    position: absolute;
    top: 45%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
  
  .nav-button:first-of-type {
    left: 0;
    rotate: 90deg;;
  }
  
  .nav-button:last-of-type {
    right: 0;
    rotate: -90deg;;

  }
  
  .overlay-content {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    height: 100px;
    text-align: center;
  }
  
  .round-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white;
    margin-bottom: 10px;
    object-fit: cover;
  }
  
  .review-text {
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .review-subtext {
    font-family: var(--font-primary);
    font-size: 16px;
    color: gray;
    margin-top: 10px;
    font-weight: 400;
  }
  


  @media (max-width: 800px) {
    .gallery-image {
      max-width: 100%;
      height: auto;
    }
    .overlay-content {
      width: 95%;
    }
  }