/* src/components/StripeFlow.css */
.stripe-flow-container {
    position: relative;
    height: 300px; /* Adjust as needed */
    width: 100%;
    margin-top: 100px;
  }
  
  .stripe-flow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to bottom, var(--background), var(--blue));
    width: 2px;
    background-color: black;
    transition: height 1s ease-out;
  }
  
  .stripe-flow-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    transition: top 1s ease-out;
  }
  