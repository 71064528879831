/* src/components/VideoCTA.css */
.video-cta {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 120px;
    height: 170px;
    background: rgba(66, 146, 221, 0.247);
    border-radius: 25px 25px 0 25px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    z-index: 1000;
    animation: appear-animation 100ms; 
  }
  

@keyframes appear-animation {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;

  }

  .image-container:hover {
      cursor: pointer;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    object-fit: cover;
    border-radius: 25px 25px 0 25px;
  }
  
  .gradient-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, black, transparent);
    border-radius: 25px 25px 0 25px;
  }
  
  .close-btn {
    position: absolute;
    top: -30px;
    right: -10px;
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  .cta-text {
    position: absolute;
    bottom: 17px;
    background-color: transparent;
    transform: translateX(-50%);
    width: 80%;
    font-family: var(--font-secondary);
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 140%;
    left: 50%;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    animation: pulsate 2s ease-in-out infinite;
  }



  @keyframes pulsate {
    0%, 100% {
      transform: translateX(-50%) scale(1);
    }
    50% {
      transform: translateX(-50%) scale(1.05);
    }
  }
  
  .video-toggle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
  }
  
  .video-toggle-button img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid rgb(228, 228, 228);
  }

  .pulse-btn {
    position: absolute;
    top: 15px;
    display: none;
    left: 15px;
    z-index: 1001;
    background: rgb(33, 218, 33);
    border: none;
    width: 8px !important;
    height: 12px !important;
    border-radius: 50%;
    cursor: pointer;
    animation: pulse 1.5s infinite;
  }
  
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }