.terms-of-use {
    color: white !important;
    background-color: var(--blue);
    padding-top: 40px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
}

.terms-of-use > a > img {
    width: 200px;
    margin-bottom: 10px;
    margin-top: 10px;
}