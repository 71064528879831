.testimonials {
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 20px; /* Adds padding to the testimonials container */
}

.testimonials > h2 {
  font-size: 52px;
  font-family: var(--font-primary);
  font-weight: 400;
  margin-left: 20px;
  margin-bottom: 5px;
}

.testimonials > p {
  font-family: var(--font-secondary);
  font-weight: 400;
  margin-left: 20px;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 30px;
}

.testimonial-video {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1300px;
}

.video-card {
  flex: 0 0 280px; /* Fixing the width of each video card */
  margin: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container {
  width: 100%;
  height: 480px; /* Fixing the height for video container */
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.video-placeholder {
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-placeholder img {
  width: 100%;
  height: 100%; /* Ensure the image covers the placeholder */
  display: block;
  border-radius: 10px;
  object-fit: cover; /* Cover the placeholder */
}

.play-icon {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensure it is exactly in the center */
  width: 40px;
  height: 40px;
  filter: invert(1);
  cursor: pointer;
}

.video-element {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  object-fit: cover; /* Cover the video card */
  display: none;
}

.video-card.playing .video-element {
  display: block;
}

.video-card.playing .video-placeholder {
  display: none;
}

.video-card .close-button {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.video-card.playing .close-button {
  display: block;
}

.video-info {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
}

.video-info > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: 14px;
}

.country-icon {
  height: 20px; /* Set height to 10 pixels */
  margin-left: 7px; /* Add some spacing between name and icon */
}

.type {
  padding: 5px 10px;
  font-family: var(--font-secondary);
  background-color: var(--blue);
  color: white;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
