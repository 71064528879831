/* src/components/LandingPage1.css */
.landing-page {
    text-align: center;
    background-color: var(--background);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 70px;
    overflow: hidden !important;
  }
  
  .landing-header {
    padding: 50px 0;
    background-color: var(--secondary-color);
    color: white;
    font-family: var(--font-primary);
  }
  