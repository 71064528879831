/* src/components/AboutProduct.css */
.about-product-container {
    text-align: center;
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
  }
  
  .about-product-subtitle {
    font-family: var(--font-secondary);
    font-size: 16px;
    margin: 0;
  }
  
  .about-product-title {
    font-family: var(--font-primary);
    font-size: 62px;
    margin: 20px 0;
    font-weight: 400;
  }
  
  .about-product-image {
    width: 90%;
    max-width: 700px;
    object-fit: contain;
    margin: 20px -30px 20px 0;
  }
  
  .about-product-description {
    font-family: var(--font-secondary);
    font-size: 20px;
    margin: 0;
    max-width: 700px;
    line-height: 180%;
    font-weight: 300;
  }
  