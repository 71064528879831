.video-wrapper-2 {
    max-width: 900px; /* Maximale Breite des Videos */
    margin: 70px 4% 50px 4%; /* Zentriert den Container */
    width: 92%; /* Nimmt den maximal verfügbaren Platz ein, aber nicht mehr als 1000px */

}


.video-wrapper-2 > h2 {
    font-family: var(--font-primary);
    font-size: 52px;
    font-weight: 400;
    margin-bottom: 10px;
}

.video-wrapper-2 > p {
    font-family: var(--font-secondary);
    font-size: 18px;
    margin-bottom: 40px;
    color: grey;
}

.video-container-2 {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Seitenverhältnis */
    height: 0;
    position: relative;
    border-radius: 25px;
}

.video-container-2 video,
.video-container-2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 25px !important;
}

.header-video-placeholder {
    cursor: pointer;
}

.play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-size: cover;
    border: none;
    cursor: pointer;
    filter: invert(1);
    background-color: transparent;
    display: none; /* Default versteckt */
}


@media (max-width: 800px) {
    .video-wrapper-2 > h2 {
        font-family: var(--font-primary);
        font-size: 62px;
        font-weight: 400;
        margin-bottom: 10px;
    }
}