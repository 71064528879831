/* src/components/SignIn.css */
.sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .google-sign-in-button {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .google-sign-in-button:hover {
    background-color: #357ae8;
  }
  
  .google-sign-in-button:focus {
    outline: none;
  }
  