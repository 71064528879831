/* src/Landingpage/Countdown.css */
.countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    opacity: 0; /* Start transparent */
    transform: translateY(-20px); /* Start position above */
    animation: fadeInSlideDown 1s forwards; /* Animation */
  }
  
  @keyframes fadeInSlideDown {
    to {
      opacity: 1; /* End fully opaque */
      transform: translateY(0); /* End position at 0 */
    }
  }

  .free-strategy-button > img {
    transform: rotate(270deg);
    width: 10px;
  }
  
  .free-strategy-button {
    background-color: rgb(230, 230, 230);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    margin-left: 10px;
    margin-right: 0px !important;
  }

  .free-strategy-button > a:hover {
    transform: scale(1.05);
  }
  
  
  .countdown {
    display: flex;
    gap: 10px;
    color: white;
    padding: 7px 15px; 
    border: 1px solid white;
    border-radius: 100px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 25px;
    background: linear-gradient(90deg, var(--blue) 25%, rgb(79, 121, 168) 50%, var(--blue) 75%);
    background-size: 300% 100%;
    animation: shimmer 15s linear infinite;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }
  
  @keyframes shimmer {
    0% {
      background-position: 300% 0;
    }
    100% {
      background-position: -300% 0;
    }
  }
  
  .time-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    border-radius: 100px;
  }
  
  .label {
    font-size: 10px;
    font-weight: 300;
    color: rgb(230, 230, 230);
  }
  
  .limited-offer {
    margin-top: 7px;
    font-size: 14px;
    color: var(--grey);
    text-align: center;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    width: 220px;
  }
  
  .limited-offer > img {
    width: 25px;
    margin-right: 10px;
    animation: swing 1s infinite alternate;
  }

  
  @keyframes swing {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  


  @media (max-width: 800px) {
    .countdown-container {
       display: none;
    
      }
  }
  