.quiz-dashboard-container {
  padding: 1.5rem;
  background-color: var(--background);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quiz-dashboard-stats-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .quiz-dashboard-stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.quiz-dashboard-stat-card {
  padding: 1rem;
  border-radius: 0.5rem;
  transition: transform 0.2s ease;
}

.quiz-dashboard-stat-card:hover {
  transform: translateY(-2px);
}

.quiz-dashboard-stat-card-blue {
  background-color: rgba(59, 130, 246, 0.1);
}

.quiz-dashboard-stat-card-green {
  background-color: rgba(16, 185, 129, 0.1);
}

.quiz-dashboard-stat-card-purple {
  background-color: rgba(139, 92, 246, 0.1);
}

.quiz-dashboard-stat-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.quiz-dashboard-stat-value {
  font-size: 1.875rem;
  font-weight: 700;
  color: var(--text-primary);
}

.quiz-dashboard-charts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .quiz-dashboard-charts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.quiz-dashboard-chart-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.quiz-dashboard-chart-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.quiz-dashboard-chart-wrapper {
  height: 16rem;
  position: relative;
}

.quiz-dashboard-table-section {
  margin-top: 1.5rem;
}

.quiz-dashboard-table-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.quiz-dashboard-table-container {
  overflow-x: auto;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.quiz-dashboard-table {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
}

.quiz-dashboard-table-header {
  background-color: rgba(243, 244, 246, 1);
}

.quiz-dashboard-table-header th {
  padding: 0.75rem 1rem;
  text-align: left;
  font-weight: 600;
  color: var(--text-primary);
}

.quiz-dashboard-table-row {
  border-bottom: 1px solid rgba(229, 231, 235, 1);
}

.quiz-dashboard-table-row:last-child {
  border-bottom: none;
}

.quiz-dashboard-table-cell {
  padding: 0.75rem 1rem;
  color: var(--text-secondary);
}

.quiz-dashboard-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: var(--text-primary);
  font-size: 1.125rem;
}

.quiz-dashboard-download-btn {
  background-color: #4F46E5;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
}

.quiz-dashboard-download-btn:hover {
  background-color: #4338CA;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quiz-dashboard-download-btn:active {
  transform: translateY(0);
}

.quiz-dashboard-table-cell:last-child {
  text-align: center;
  width: 100px;
}