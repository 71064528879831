/* src/components/StepExplainer.css */
.step-explainer-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    max-width: 1000px;
    width: 90%;
}

.vertical-line-container {
    position: absolute;
    left: 25%;
    top: 40px;
    height: 100%;
}

.vertical-line {
    position: absolute;
    left: 0;
    width: 2px;
    background: linear-gradient(to bottom, var(--background), var(--blue));
    transition: height 0.3s ease-out, opacity 0.5s ease-out;
}

.vertical-line.fade-out {
    opacity: 0;
}

.vertical-line-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-48%);
    width: 40px;
    height: 40px;
    transition: top 0.3s ease-out, opacity 0.5s ease-out;
}

.vertical-line-icon.fade-out {
    opacity: 0;
}

.steps-container {
    flex-grow: 1;
    width: 100%;
}

.step {
    display: flex;
    margin-bottom: 150px;
    position: relative;
}

.step-left {
    width: 20%;
    text-align: right;
    padding-right: 20px;
}

.step-right {
    width: 70%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.step h2 {
    font-family: var(--font-secondary);
    font-size: 36px;
    font-weight: 600;
    margin-top: 20px;
    font-weight: 40;
}

.step h3 {
    font-family: var(--font-primary);
    font-size: 68px;
    margin: -10px 0 0 0;
    font-weight: 300;
    text-align: left;
    margin-left: 40px;
}

.step-image {
    height: 500px;
    object-fit: contain;
    max-width: 700px;
}

.step-image-container {
    display: flex;
    align-items: center;
    margin: 60px 0 60px 30px;
}

.step p {
    font-family: var(--font-secondary);
    font-size: 18px;
    max-width: 600px;
    line-height: 200%;
    text-align: left;
    margin-left: 40px;
}


@media (max-width: 1000px) {

.step-explainer-container {
    max-width: unset;
}

}
@media (max-width: 900px) {
    .step h2 {
        display: none;
    }

    /* src/components/StepExplainer.css */
.step-explainer-container {
    padding: 40px 20px;
}


    .step-image-container {
        margin: 0px;
    }
.vertical-line-container {
    position: absolute;
    left: 25px;
    top: 40px;
    height: 100%;
}
.step-right {
    width: 90%;
}

.step-left {
    width: unset;
}

.step {
    margin-bottom: 40px;
}

.step p {
    margin-left: 0px;
}

.step-image {
    width: 100%;
    height: unset;
}

.step h3 {
    font-family: var(--font-primary);
    font-size: 42px;
    font-weight: 300;
    text-align: left;
    margin-left: 0px;
    margin-top: -10px;
}
    
}