.quiz-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* Anfangs ausgeblendet (nach oben verschoben) */
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dfe7f7a6;
    backdrop-filter: blur(10px);
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  
  /* Sichtbarer Zustand ab 50px Scroll */
  .quiz-header.visible {
    transform: translateY(0);
  }
  
  /* Logo links (halb so groß wie vorher) */
  .quiz-header-left img {
    height: 30px;
    width: auto;
    margin-left: 30px;
  }

  .quiz-header-left {
      width: 33.33%;
  }
  .quiz-header-right {
    width: 33.33%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
  
  /* Countdown mittig */
  .quiz-header-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
  }
  
  .quiz-header-text {
    font-size: 0.7rem;
    font-weight: 600;
    display: none;
    margin-bottom: 0.2rem;
    color: #333;
  }
  
/* Wrapper für die einzelnen Countdown-Segmente */
.quiz-header-countdown-wrapper {
    display: flex;
    gap: 1.2rem; /* Abstand zwischen den Segmenten */
  }
  
  /* Jedes Segment (z.B. Day, Hour, Min, Sec) */
  .quiz-header-countdown-segment {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Zahlen selbst */
  .quiz-header-countdown-value {
    font-size: 1.4rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 0;
  }
  
  /* Label unter der Zahl */
  .quiz-header-countdown-label {
    font-size: 0.7rem;
    color: #333;
    margin-top: -2px;
  }
  /* Button rechts */
  .quiz-header-right .quiz-header-apply-button {
    background-color: var(--blue);
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    margin-right: 30px;
    border-radius: 100px;
  }
  
  .quiz-header-right .quiz-header-apply-button:hover {
    opacity: 0.85;
  }
  
  /* Responsive (optional) */
  @media (max-width: 600px) {
    .quiz-header-center {
      margin: 0.5rem 0;
    }
    /* Wrapper für die einzelnen Countdown-Segmente */
.quiz-header-countdown-wrapper {
  display: none;
}
  }
  