/* Allgemeines Layout wie gehabt ... */
.quiz-step-container {
  text-align: center;
  background-color: #FCF9F1;
  min-height: 100vh;
  padding: 20px 50px 0px 50px;

}

.quiz-step-container > h2 {
  text-align: left;
  margin-top: 30px;
  font-weight: 300;
  font-size: 104px;
  font-family: 'Gaia';
  max-width: 800px;
  line-height: 0.9;
}

.quiz-step-container > p {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  color: grey;
  font-size: 20px;
  font-family: var(--font-secondary);
  max-width: 800px;
  line-height: 0.9;
}

/* Allgemeines Layout wie gehabt ... */
.end-step-container {
  text-align: center;
  background-color: #FCF9F1;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 0px;

}

.end-step-container > h2 {
  text-align: center;
  margin-top: 0px;
  font-weight: 300;
  font-size: 104px;
  font-family: 'Gaia';
  line-height: 0.9;
}

.end-step-container > p {
  text-align: left;
  font-weight: 400;
  color: grey;
  font-size: 20px;
  margin: 20px 10px 0 10px;
  font-family: var(--font-secondary);
  max-width: 800px;
  line-height: 1;
}

.end-step-container > a {
  padding: 0 30px;
  height: 80px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 100px;
  text-decoration: none;
  font-family: var(--font-secondary);
  font-size: 18px;
  margin-top: 50px;
}

.quiz-button-group {
  display: inline-flex;
  flex-wrap: wrap; /* Falls du mehrere Buttons in Zeilen umbrechen willst */
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.quiz-number-counter > button {
  border-radius: 100px;
  width: 45px;
  height: 45px;
  border: 2px solid black;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  font-size: 0;
}

.quiz-number-counter > button > img {
  width: 15px;
}

.quiz-number-counter > button:hover {
  cursor: pointer;
}

.quiz-number-counter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.quiz-number-counter > span {
    font-size: 154px;
    font-family: 'Gaia';
    font-weight: 300;
    color: var(--blue);
    line-height: 0.8;
}

.yes-button {
  padding: 0 40px;
  height: 70px;
  font-family: var(--font-secondary);
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 40px;
  border-radius: 100px;
  border: none;
  color: black;
  outline: 1px solid black;
  font-weight: 500;
  background-color: white;
}

.yes2-button {
  padding: 0 40px;
  height: 70px;
  font-family: var(--font-secondary);
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 100px;
  border: none;
  color: white;
  outline: 1px solid black;
  font-weight: 500;
  background-color: var(--blue);
}

.yes2-button:disabled {
  background-color: rgb(151, 151, 151);
  color: black;
}

.no-button {
  padding: 0 40px;
  height: 70px;
  font-family: var(--font-secondary);
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 100px;
  border: none;
  color: black;
  outline: 1px solid black;
  font-weight: 500;
  background-color: white;
}

.yes-button:hover, .no-button:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.yes2-button:hover {
  transform: scale(1.03);
  cursor: pointer;
}

/* ---------- ButtonSelectStep spezifisch ---------- */
.quiz-button-selector {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.quiz-select-button {
  background-color: transparent;
  border: 2px solid black;
  padding: 24px 40px;
  color: black;
  font-size: 20px;
  cursor: pointer;
  border-radius: 100px;
  margin: 10px 0;
}

.quiz-select-button.selected {
  background-color: var(--skyblue);
  color: black;
  border: 2px solid var(--blue);
}

.quiz-country-container {
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: left;
  justify-content: left;
}

.quiz-select-button:last-child {
  margin-bottom: 10px;
}

.quiz-country-selector {
  font-size: 52px;
  margin-top: 0px;
  padding-top: 0px;
  color: black;
  border: 0;
  background-color: transparent;
  outline: none;
  font-family: 'Gaia';
  font-weight: 300;
  text-decoration: underline;
}

.quiz-country-selector:hover {
  cursor: pointer;
  color: var(--blue);
}

.quiz-input-group {
  display: flex;
}

.quiz-icon {
  width: 23px;
  margin-right: 6px;
}

.quiz-checkbox-group > label {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: var(--font-secondary);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px; /* Adjust text size */
}

.quiz-checkbox-group input[type="checkbox"] {
  width: 25px;  /* Make checkbox larger */
  height: 25px;
  cursor: pointer;
  border: 1px solid black;
  margin-right: 10px;
}

.quiz-checkbox-group a {
  color: #007bff; /* Blue color for link */
  text-decoration: none;
  margin-left: 4px;
  font-weight: bold;
}

.quiz-checkbox-group a:hover {
  text-decoration: underline; /* Underline on hover */
}


.quiz-pseudofield {
  background-color: transparent;
  margin: 5px 0;
  border: 1.5px solid black;
  max-width: 400px;
  width: 96%;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
}

.quiz-input-group > label > input {
  border: none;
  background-color: transparent;
  font-size: 18px;
  width: 100%;
  height: 100%;
  font-family: var(--font-secondary);
  outline: none;
  margin-left: 10px;
}



#quiz_age_font {
  font-size: 142px;
}


@media(max-width: 780px) {
  .quiz-step-container > h2 {
    font-size: 62px;
  }
  .quiz-step-container {
    padding: 20px 15px 0  15px;
  }
  .apply-quiz-head {
    padding-right: 15px !important;
    padding-top: 15px !important;
    padding-bottom: 20px !important;
  }
  #quiz_age_font {
    font-size: 92px;
  }
  .quiz-number-counter > span {
    font-size: 132px;
  }

  .end-step-container > h2 {
    font-size: 84px;
  }

}


@media(max-width: 490px) {
  .quiz-step-container > h2 {
    font-size: 58px;
    line-height: 1;
  }
  .quiz-button-selector {
    width: 100% !important;
  }
  .quiz-country-selector {
    font-size: 38px;
  }
}

@media(max-width: 430px) {
  .quiz-step-container > h2 {
    font-size: 53px;
    line-height: 1;
  }
  .quiz-step-container > p {
    line-height: 1.2;
    margin-top: -10px;
    font-size: 18px;
  }
}


@media(max-width: 406px) {
  .quiz-step-container > h2 {
    font-size: 49px;
    line-height: 1;
  }
  .start-continueBtn {
    font-size: 22px;
    width: 100%;
  }
}

@media(max-width: 365px) {
  .quiz-step-container > h2 {
    font-size: 38px;
    line-height: 1;
  }
}

@media(max-width: 345px) {
  .quiz-step-container > h2 {
    font-size: 32px;
    line-height: 1;
  }
  .quiz-number-counter > span {
    font-size: 92px;
  }
}