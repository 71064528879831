/* src/pages/ActionPage.css */
.action-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background: linear-gradient(to top, var(--skyblue), var(--background));
    border-radius: 50px;
    margin: 20px 0 100px 0;
    height: 800px;
}
.action-image {
    width: 200px;
}

.action-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    align-items: center;
}

.action-text-content {
    padding: 20px;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    align-items: left;
    flex-direction: column;
    height: 100%;
    margin-top: 15%;
}

.action-button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .action-button.active {
    background-color: var(--blue); /* Example active color */
    color: white;
  }


.action-small-text {
    font-family: var(--font-secondary);
    font-size: 16px;
    margin-bottom: -20px;
    text-transform: uppercase;
    color: var(--blue);
    margin-top: 50px;
}

.action-title {
    font-family: var(--font-primary);
    font-size: 52px;
    margin-bottom: 0px;
    font-weight: 400;
}

.action-description {
    font-family: var(--font-secondary);
    font-size: 18px;
    margin-bottom: 40px;
    max-width: 500px;
    line-height: 160%;
    margin-top: 0px;
}

.action-button {
    background-color: var(--black);
    color: white;
    font-family: var(--font-secondary);
    font-size: 18px;
    height: 60px;
    width: 150px !important;
    border: none;
    border-radius: 100px;
    cursor: pointer;
}

.image-content {
    display: flex;
    min-width: 380px;
    justify-content: center;
    align-items: center;
    position: relative; /* Position relative to allow absolute positioning of the overlay */
}

.sample-image {
    max-width: 450px;
    width: auto;
    border-radius: 25px;
}

/* New styles for the overlay */
.overlay-container {
    position: absolute;
    top: 75px;
    left: 79px;
    width: 68%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: var(--font-secondary);
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 44px; /* Match the image border radius */
    text-align: center;
    overflow: hidden; /* Hide overflow for the entire overlay */
}

.scrollable-image {
    width: 100%;
    height: 100%; /* Adjust as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.overlay-image {
    width: 100%;
    height: auto;
}



@media (max-width: 1250px) {
    .action-page-container {
        display: none;
    }
}