/* src/components/BreakBar.css */
.break-bar {
    overflow: hidden;
    width: 100%;
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
  
  .logo {
    width: 150px;
    max-height: 70px;
    margin: 0 40px;
    object-fit: contain;
  }
  
  