.quiz_start_page {
  width: 100%;
  overflow: hidden;
}


.freelance_start_page {
    /* Beispielhafte Einstellungen */
    text-align: left;
    padding: 40px 40px 60px 40px;
    background-color: #FCF9F1;
    color: #1F1E1C;
    font-family: 'Gaia', serif;
  }
  
  .start-introText {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: var(--font-secondary);
    color: var(--blue);
  }
  
  .quiz-start-heading {
    font-size: 92px;
    font-weight: 200;
    line-height: 1.3;
    margin: 0px 0 40px;
    font-family: 'Gaia', serif;
  }


  
  /* 
     1) Div-Container für die Buttons und den Wert 
     2) Inline-Block + vertical-align: middle => alles bleibt in einer Zeile 
  */
  .countContainer,
  .targetContainer {
    display: inline-block;
    vertical-align: start;
    text-align: left;  /* falls du den Inhalt zentrieren willst */
    margin: -15px 5px 0px 5px;       /* seitlicher Abstand */
  }
  
  .countValue,
  .targetValue {
    margin: 0 8px;
    border-bottom: 5px solid black;
    width: 100%;
  }

  .start-btn:focus,
.start-btn:active {
  outline: none;      /* entfernt den Standard-Fokus-Ring */
  box-shadow: none;   /* entfernt eventuelle Box-Shadow-Styles */
  background: none;   /* falls du eine Hintergundfarbe hattest */
}

  
  /* Gemeinsames Styling für die kleinen Buttons */
  .start-btn {
    display: inline-block;  
    vertical-align: middle; 
    font-size: 0px;
    border: none;
    background: transparent;
    border: 2px solid black;
    width: 40px !important;
    border-radius: 100px;
    height: 40px !important;
    cursor: pointer;
    margin: 0px 5px 15px 5px; 
  }


  
  
  /* Großer "Continue"-Button */
  .start-continueBtn {
    margin-top: 0px;
    padding: 14px 40px;
    height: 88px;
    font-family: 'Gaia', serif;
    background-color: var(--blue);
    color: #fff;
    border: none;
    font-size: 36px;
    font-weight: 300;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 100ms ease-in-out;
  }
  
  .start-continueBtn:hover {
   transform: scale(1.01);
  }
  

  @media(max-width: 580px) {
    .quiz-start-heading {
      font-size: 59px;
    }
    .start-continueBtn {
      font-size: 26px;
      width: 100%;
    }
    .freelance_start_page {
      padding: 40px 10px 40px 10px;
      text-align: center;
    }
  }

  @media(max-width: 456px) {
    .quiz-start-heading {
      font-size: 49px;
    }
  }

  @media(max-width: 406px) {
    .quiz-start-heading {
      font-size: 42px;
      line-height: 1.5;
    }
    .start-continueBtn {
      font-size: 22px;
      width: 100%;
    }
  }

  @media(max-width: 365px) {
    .quiz-start-heading {
      font-size: 38px;
      line-height: 1.5;
    }
  }

  @media(max-width: 345px) {
    .quiz-start-heading {
      font-size: 32px;
      line-height: 1.5;
    }
  }