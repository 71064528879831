.apply-info-wrapper {
    background-color: var(--background); /* Passt zum Style der StartPage */
    padding: 3rem 0rem;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .apply-info-title {
    font-size: 104px;
    max-width: 800px;
    font-family: 'Gaia';
    font-weight: 300;
    margin-bottom: 1rem;
    color: #333;
    margin-top: 0px;
    line-height: 1;
  }

  .apply-info-wrapper > h5 {
      background-color: var(--blue);
      padding: 7px 15px;
      border-radius: 100px;
      font-size: 14px;
      color: white;
      margin-bottom: 20px;
      margin-top: 50px;
  }
  
  .apply-info-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 3rem;
    line-height: 2;
    max-width: 800px;
    margin: 10px 30px 30px 30px;
    
  }
  
  .apply-info-box-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  .apply-info-box {
    background-color: #FFFFFF;
    border-radius: 35px;
    padding: 2rem;
    flex: 1;
    max-width: 300px;
    min-height: 400px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .apply-info-box-image-wrapper {
    margin-bottom: 1rem;
  }
  
  .apply-info-box-image {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .apply-info-box-title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .apply-info-box-text {
    font-size: 0.95rem;
    color: #555;
    line-height: 1.4;
  }



  .apply-info2-wrapper {
    /* Hintergrund, Ausrichtung, etc. 
       Passe ggf. an deine bestehende Farbpalette an */
    background-color: #F9F8F6;
    text-align: center;
    padding: 3rem 1rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .apply-info2-mainTitle {
    font-size: 104px;
    font-family: 'Gaia';
    text-align: center;
    color: #333;
    max-width: 800px;
    margin-bottom: 1rem;
    font-weight: 200;
    line-height: 1;
  }
  
  .apply-info2-subtitle {
    font-size: 1rem;
    color: #666;
    margin-top: 30px;
    margin-bottom: 3rem;
    max-width: 800px;
    line-height: 2;
  }
  
  /* Container für die beiden Spalten + Pfeil in der Mitte */
  .apply-info2-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 0rem;
  }
  
  /* Allgemeine Spalten-Klasse */
  .apply-info2-col {
    background-color: #fff;
    border-radius: 35px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 1.5rem;
    flex: 1;
    max-width: 450px;
    margin: 0 20px;
  }

  .apply-info2-col-right {
    background-color: var(--blue);
  }

  .apply-info2-col-right > h3 {
    color: white;
  }
  .apply-info2-col-right li {
    color: white !important;
    font-weight: 600 !important;
  }

  .apply-info2-col-right li > img {
    filter: invert(1);
  }
  
  
  /* Bilder in den Spalten */
  .apply-info2-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin-bottom: 1rem;
  }
  
  /* Titel in der Spalte */
  .apply-info2-col-title {
    font-size: 42px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Gaia';
    color: #333;
    line-height: 1;
  }
  
  /* Liste der Bullet Points */
  .apply-info2-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .apply-info2-list li {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    font-size: 20px;
    color: rgb(109, 108, 108);
    font-weight: 400;
    text-align: left;
  }
  
  .apply-info2-bullet-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  /* Pfeil in der Mitte */
  .apply-info2-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .apply-info2-arrow img {
    width: 40px;
    height: 40px;
    /* Original-Icon zeigt nach unten -> 
       Auf Desktop drehen wir es nach rechts (90deg) */
    transform: rotate(90deg);
  }


/* Umschließender Container */
.apply-info3-wrapper {
    /* Hintergrund, Ausrichtung, etc. 
       Passe ggf. an deine bestehende Farbpalette an */
    background-color: #F9F8F6;
    text-align: center;
    padding: 3rem 1rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .apply-info3-mainTitle {
    font-size: 87px;
    font-family: 'Gaia';
    text-align: center;
    color: #333;
    max-width: 800px;
    margin-bottom: 30px;
    font-weight: 200;
    line-height: 1.1;
  }
  
  /* Container für alle "Punkte" */
  .apply-info3-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem; /* Abstand zwischen den Items */
    width: 100%;
  }
  
  /* Einzelner Punkt */
  .apply-info3-item {
    background-color: transparent; /* Nur Rahmen, kein zusätzliches BG */
    border: 1px solid #E0E0E0; 
    border-radius: 30px;
    padding: 2rem;
    width: 96%;
    max-width: 800px;
    box-sizing: border-box;
    text-align: center;
  }
  
  /* Icon-Wrapper oben im Item */
  .apply-info3-icon-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  /* Icon selbst */
  .apply-info3-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  
  /* Titel im Item */
  .apply-info3-item-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  /* Beschreibungstext */
  .apply-info3-item-text {
    font-size: 0.95rem;
    color: #555;
    line-height: 1.4;
  }

  .apply-info4-wrapper {
    /* Sorgt dafür, dass der Hintergrund das gesamte Elternelement füllt */
    width: 100%;
    height: 800px; /* oder beliebig anpassen */
    background-size: cover;      /* skaliert das Bild, damit es den Container füllt */
    background-position: center; /* zentriert das Bild */
    background-repeat: no-repeat;
    
    /* Optional: Abstand oder anderes Layout */
    margin: 0 auto;
    /* Wenn du einen festen Seitenrand oder ähnliches brauchst, 
       kannst du hier Padding einfügen. */
  }

  

/* Umgebender Wrapper */
.apply-info5-wrapper {
    background-color: #F9F8F6; /* passt zum Style der anderen Komponenten */
    padding: 2rem 1rem 2rem 1rem;
    text-align: center;
  }
  
  /* Großer Titel oben (z.B. "We are Looking for") */
  .apply-info5-heading {
    font-size: 104px;
    font-family: 'Gaia';
    font-weight: 300;
    margin-bottom: 40px !important;
    margin: 0 40px;
    color: #111;
  }
  
  /* Die "Karte" selbst */
  .apply-info5-card {
    background: #fff;
    /* Abgerundete Ecken nur oben, wie im Screenshot */
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  
    width: 100%;
    margin: 0 auto;
  
    /* Damit der Inhalt bei zu vielen Requirements scrollt, 
       haben wir eine feste Höhe und flex layout */
    display: flex;
    flex-direction: column;
    height: 700px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  /* Header-Bereich in der Karte */
  .apply-info5-card-header {
    padding: 30px;
    position: relative;
    text-align: left;
  }
  
  /* Kleines Badge für Location */
  .apply-info5-location {
    display: inline-block;
    background-color: #F0F0F0;
    border-radius: 20px;
    padding: 0.3rem 0.8rem;
    font-size: 0.85rem;
    color: #333;
  }
  
  /* Job-Titel */
  .apply-info5-position {
    font-size: 52px;
    font-family: 'Gaia';
    font-weight: 300;
    margin: 0.75rem 0 0 0;
    color: #333;
  }
  
  /* Icon oben rechts */
  .apply-info5-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 50px;
    height: 50px;
    background-color: rgb(238, 238, 238);
    border-radius: 100px;
    padding: 20px;
  }
  
  /* Scrollbarer Bereich für Requirements */
  .apply-info5-card-content {
    flex: 1; /* füllt den verfügbaren Platz */
    overflow-y: auto;
    padding: 0 30px;
    text-align: left;
  }
  
  /* Überschrift für Requirements */
  .apply-info5-requirements-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 0;
  }
  
  /* Liste der Requirements */
  .apply-info5-requirements-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .apply-info5-requirements-list li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: rgb(10, 10, 10);
    background-color: rgb(245, 245, 245);
    padding: 25px 20px;
    border-radius: 7px;
  }
  
  /* Icon (Häkchen) pro Requirement */
  .apply-info5-tick-icon {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
  
  /* Fußbereich mit Button am unteren Rand */
  .apply-info5-card-footer {
    flex-shrink: 0;
    background: linear-gradient(
      270deg, 
      var(--blue),
      #87CEEB,
      #87CEEB,
      var(--blue)
    );
    background-size: 200% 200%;
    animation: gradient 4s ease infinite;
    text-align: center;
    padding: 1rem;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  
  .apply-info5-apply-button {
    background: none;
    color: #fff;
    border: none;
    font-size: 56px;
    height: 120px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Gaia';
    font-weight: 200;
  }

  .apply-info5-card-footer:hover {
      cursor: pointer;
  }
  
  .apply-info5-apply-button:hover {
    text-decoration: underline;
  }
  
    /* --- Responsive Breakpoint --- */
    @media (max-width: 968px) {
      .apply-info2-col-title {
        font-size: 32px;
      }
    }

  /* --- Responsive Breakpoint --- */
  @media (max-width: 768px) {
    .apply-info2-row {
      flex-direction: column;
    }
    .apply-info2-col-title {
      font-size: 32px;
    }

    /* Pfeil soll dann wieder nach unten zeigen (0deg) */
    .apply-info2-arrow img {
      transform: rotate(0deg);
      margin: 1rem 0;
    }

    .apply-info4-wrapper {
      height: 400px;
    }
  
    .apply-info2-col {
      max-width: 500px;
      margin-bottom: 2rem;
    }
    .apply-info3-items {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .apply-info-box-container {
        flex-direction: column;
        align-items: center;
      }
    
      .apply-info-box {
        max-width: 500px;
        margin-bottom: 2rem;
        width: calc(90% - 4rem);
      }

      .apply-info5-heading {
        font-size: 38px !important;
      }

      .apply-info2-mainTitle {
        font-size: 84px;
      }

      .apply-info2-subtitle {
        margin: 30px;
      }

      .apply-info2-wrapper {
        padding-top: 0 !important;
      }
    

      .apply-info3-mainTitle {
        font-size: 82px;
      }

      .apply-info-title {
        font-size: 84px;
        margin: 0 15px;
      }
    
    
      .apply-info5-icon {
        width: 40px;
        height: 40px;
      }
  }
  
  @media (max-width: 620px) {
    .apply-info-title {
      font-size: 64px;
    }


    .apply-info5-position {
      font-size: 24px;
    }

    .apply-info5-icon {
      width: 30px;
      height: 30px;
    }

    .apply-info3-mainTitle {
      font-size: 64px;
    }

    .apply-info5-apply-button {
      background: none;
      color: #fff;
      border: none;
      font-size: 36px;
      height: 50px;
      font-weight: 600;
      cursor: pointer;
      font-family: 'Gaia';
      font-weight: 200;
    }

    .apply-info2-mainTitle {
      font-size: 64px;
    }
    .apply-info2-col {
      margin: 20px 0;
    }
     /* Titel in der Spalte */

  }


  @media (max-width: 450px) {
    .apply-info3-mainTitle {
      font-size: 48px;
    }
    .apply-info2-mainTitle {
      font-size: 48px;
    }
    .apply-info-title {
      font-size: 48px;
    }
  }


  @media (max-width: 400px) {
    .apply-info4-wrapper {
      height: 300px;
    }
    .apply-info5-position {
      font-size: 18px;
    }

    .apply-info5-icon {
      width: 20px;
      height: 20px;
    }

  }

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.apply-now-badge {
  background: linear-gradient(
    270deg, 
    var(--blue),
    #87CEEB,
    #87CEEB,
    var(--blue)
  );
  background-size: 200% 200%;
  animation: gradient 4s ease infinite;
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

@keyframes fadeSlideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Für alle großen Header-Texte */
.apply-info-title,
.apply-info2-mainTitle,
.apply-info3-mainTitle,
.apply-info5-heading {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeSlideUp 0.8s ease-out forwards;
  animation-play-state: paused;
}

/* Klasse die wir hinzufügen wenn das Element sichtbar wird */
.animate-visible {
  animation-play-state: running;
}