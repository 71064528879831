/* src/Landingpage/PasswordProtection.css */
.passwordProtectionContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e2eff4; /* Hellblauer Hintergrund wie im Screenshot */
  z-index: 9999;
  font-family: var(--font-primary);
  gap: 40px;
}

/* Kreisförmiges Logo mit pulsierender Animation */
.passwordProtectionLogoCircle {
  width: 80px;
  height: 80px;
  margin-top: -100px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4080aa; /* Blauer Kreis */
  margin-bottom: 0px;
  box-shadow: 0 0 20px rgba(104, 151, 183, 0.167);
  animation: passwordProtectionPulsate 3s ease-in-out infinite;
  position: relative;
}

.passwordProtectionLogoCircle::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid rgba(104, 151, 183, 0.3);
  animation: passwordProtectionPulsateRing 3s ease-in-out infinite;
  z-index: -1;
}

.passwordProtectionLogoCircle img {
  width: 60px;
  height: auto;
  object-fit: contain;
}

/* Passwortbereich */
.passwordProtectionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
}

/* Überschrift */
.passwordProtectionHeading {
  color: #6897B7;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
  font-family: 'Jost', sans-serif;
  margin: 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(104, 151, 183, 0.15);
}

/* Container für die Passwort-Eingabefelder */
.passwordProtectionInputWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

/* Container für einzelnes Eingabefeld und Platzhalter */
.passwordProtectionInputContainer {
  position: relative;
  width: 60px;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  height: 60px;
}

/* Stil für die einzelnen Zeichen-Eingabefelder */
.passwordProtectionCharInput {
  width: 100%;
  height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 20px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  color: #6897B7; /* Blaue Textfarbe */
  text-transform: uppercase; /* Zeigt alle Buchstaben als Großbuchstaben an */
  font-weight: 500;
}

.passwordProtectionCharInput:focus {
  outline: none;
  box-shadow: 0 3px 8px rgba(104, 151, 183, 0.3);
  background-color: rgba(255, 255, 255, 1);
}

/* Platzhalter-Stern */
.passwordProtectionPlaceholderStar {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #6897B7;
  font-size: 28px;
  pointer-events: none; /* Erlaubt Klicks durch den Stern hindurch auf das Input-Feld */
}

/* Fehlerzustand für Eingabefelder */
.passwordProtectionCharInput.passwordProtectionError {
  border: 2px solid rgba(255, 82, 82, 0.3);
  animation: passwordProtectionShake 0.5s cubic-bezier(.36,.07,.19,.97) both;
}

/* Enter Button */
.passwordProtectionEnterButton {
  background-color: #6897B7;
  color: white;
  border: none;
  border-radius: 100px;
  padding: 14px 40px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  animation: passwordProtectionFadeIn 0.5s ease-out;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  height: 70px;
  width: 250px;
}

.passwordProtectionEnterButton:hover {
  background-color: #587d98;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.passwordProtectionEnterButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Animationen */
@keyframes passwordProtectionShake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}

@keyframes passwordProtectionFadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Pulsierende Animation für den Logo-Kreis */
@keyframes passwordProtectionPulsate {
  0% {
    box-shadow: 0 0 15px rgba(104, 151, 183, 0.5);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 25px rgba(104, 151, 183, 0.7);
    transform: scale(1.03);
  }
  100% {
    box-shadow: 0 0 15px rgba(104, 151, 183, 0.5);
    transform: scale(1);
  }
}

/* Pulsierende Animation für den äußeren Ring */
@keyframes passwordProtectionPulsateRing {
  0% {
    opacity: 0.4;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.4;
    transform: scale(1);
  }
}


