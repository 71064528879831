
.footer {
    background: linear-gradient(to bottom, var(--background), var(--skyblue));
    padding: 40px 0px;
    font-size: 14px;
    width: 100%;
    
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    color: rgb(46, 46, 46);
    margin: 0 5%;
}

.footer-column {
    flex: 1;
    min-width: 200px;
    text-align: left;
}

.footer-logo {
    width: 150px;
    margin-bottom: 20px;
}

.footer-social {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.footer-social a img {
    width: 20px;
    height: 20px;
    margin-top: 10px;
}

.footer-column h3 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.legal-list {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-direction: column;
    margin-left: 0px;
    margin-block-start: 0px !important;
}

.legal-list a {
    color: rgb(46, 46, 46);
    text-align: left;
    margin: 5px 0;
}

.footer-column ul li a:hover {
    text-decoration: underline;
}

.footer-column > p > a {
    color: black;
}

.cta-button-2 {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: black;
    color: white;
    font-weight: 400 !important;
    text-decoration: none;
    border-radius: 100px;
    font-weight: bold;
}

.cta-button:hover {
    background-color: rgb(44, 44, 44)k;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgb(255, 255, 255);
    padding-top: 20px;
    margin-top: 20px;
    font-size: 12px;
}

.footer-bottom p {
    margin: 0 5%;

}


@media (max-width: 800px) {
    .footer-container {
        width: 90%;
    }
}