/* src/pages/CalendlyPage.css */
.calendly-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    min-width: 1000px;
  }
  
  .calendly-page-title {
    font-family: var(--font-primary);
    font-size: 68px;
    margin-bottom: 20px;
    font-weight: 400;
    max-width: 500px;
  }

  .calendly-bullet-points {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -40px;
    padding-bottom: 50px;
    border: 1px solid rgb(240, 240, 240);
    width: 90%;
    margin-top: -20px;
    max-width: 400px;
    background-color: #fbfcfd;
    border-radius: 25px;
  }
  
  .calendly-bullet-point {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid rgba(204, 204, 204, 0.267);
    background-color: white;
    padding: 0 15px;
    border-radius: 12px;
    width: 90%;
    max-width: 340px;
    font-family: var(--font-secondary);
  }
  
.calendly-bullet-point > p {
  margin: 14px 0;
  font-size: 14px;
  font-weight: 400;
}

  .calendly-bullet-icon {
    width: 15px;
    height: 24px;
    margin-right: 15px;
  }

  .calendly-bullet-points > p {
    font-size: 14px;
    font-family: var(--font-secondary);
    font-weight: 600;
  }
  
  .page-description {
    font-family: var(--font-secondary);
    font-size: 18px;
    margin-bottom: 60px;
    color: grey;
    margin-top: 0px;
    text-align: center;
    max-width: 800px;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 600px;
  }
  
  .calendly-embed {
    width: 100%;
    max-width: 800px;
    height: 700px;
    border: 1px solid #ddd;
    border-radius: 25px;
    overflow: hidden;
  }
  


  @media (max-width: 1000px) {
    .calendly-page-title {
      font-size: 42px;
    }

    .calendly-page-container {
      min-width: 90%;
      padding: 40px 0;
    }
  }


  @media (max-width: 800px) {
    .calendly-page-title {
      font-size: 42px;
    }
      
  .calendly-bullet-point {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid rgb(235, 235, 235);
    background-color: white;
    padding: 0 12px;
    border-radius: 12px;
    width: 80%;
    max-width: 340px;
    font-family: var(--font-secondary);
  }

  }