/* src/components/BenefitComponent2.css */
.benefit2-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 0;
    width: 100%;
  }
  
  .image2-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  
  .benefit2-image {
    width: 100%;
    height: 100%;
    max-height: 650px;
    max-width: 650px;
    object-fit: contain;
    margin-left: 50px;
  }
  
  .benefit2-component {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .benefit2-container {
    width: 80%;
    padding: 30px 30px;
    border-radius: 20px;
    display: flex;
    max-width: 550px;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    margin-right: 5%;
    box-shadow: rgba(17, 12, 46, 0.05) 0px 28px 30px 0px;  
}
  

  
  .header2 {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    margin-top: -10px;
  }


  .icon2 {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .header2-text {
    font-size: 16px;
    flex-grow: 1;
  }

  .absolute2-text {
    position: absolute;
    right: 0px;
    top: 5px;
    color: grey;
    font-size: 12px;
    text-transform: uppercase;
  }
  
  
  .benefit2-title {
    font-family: var(--font-primary);
    font-size: 48px;
    margin: 20px 0;
    text-align: left;
    font-weight: 400;
    max-width: 400px;
  }
  
  .benefit2-description {
    font-family: var(--font-secondary);
    font-size: 16px;
    margin: 10px 0;
    font-weight: 300;
    line-height: 150%;
    text-align: left;
    margin-right: 25px;
  }
  
  .benefit2-wrapper-button {
    background-color: var(--black);
    color: white;
    font-family: var(--font-secondary);
    font-size: 16px;
    border: none;
    border-radius: 100px;
    height: 60px;
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
  }
  


  .benefit-image-inside {
    display: none;
  }


  @media(max-width: 1300px) {
    .benefit2-image {
      width: 90%;
      margin-left: 20px;
      object-fit: contain;
    }
    .image2-wrapper {
      margin-right: 5%;
      justify-content: center;
    }

    .benefit2-container {
      margin-left: 5%;
    }
  }
  

  @media(max-width: 800px) {
    .benefit2-wrapper {
      height: unset;
      flex-direction: column;
    }
    .benefit2-image {
      height: 100%;
      width: 90%;
      object-fit: contain;
      margin-right: 0px;
      display: none;
  
    }
    .benefit-image-inside {
      display: flex;
      width: 90%;
      margin-left: 5%;
    }
     
  .benefit2-component {
    width: 100%;
  }
  .image2-wrapper {
    width: 90%
  }

  .benefit2-container  {
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
  }
    
  }